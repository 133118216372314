<template>
  <div>
    <div class="card card-custom card-sticky" v-if="!mostrarDetalleFicha">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label cursor-pointer">
            <span class="label label-xl label-dot label-success mr-2"></span>
            Turno 3 <i class="mr-2"></i>
            <small v-if="ui.estaCargandoDatos" class="">Cargando datos de la ficha...</small>
            <small v-else-if="auth.mensajeNoAutorizadoEdicionTurno" class="text-danger">{{ auth.mensajeNoAutorizadoEdicionTurno }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <a href="#" class="btn btn-sm btn-light font-weight-bold" @click.prevent="doCargarFicha()"><i class="fa p-0 fa-sync" :class="[ui.estaCargandoDatos ? 'fa-spin ' : '']"></i></a>
        </div>
      </div>
    </div>
    <div class="card card-custom card-sticky" v-if="mostrarDetalleFicha">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label cursor-pointer" @click.prevent="$emit('update:expandirContenido', !expandirContenido)">
            <span class="label label-xl label-dot label-success mr-2"></span>
            Turno 3 <i class="mr-2"></i>
            <small class="">{{ auth.fechaInicioTurno | time }} - {{ auth.fechaFinTurno | time }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <span v-if="auth.puedeCambiarEstadoFinalizado == true">
            <a href="#" v-if="finalizado == true" class="btn btn-sm btn-light-danger font-weight-bold mr-2" v-b-tooltip.left title="Presione para desbloquear" @click.prevent="doToggleFinalizado()"> <i class="fa fa-lock"></i>Finalizado</a>
            <a href="#" v-if="finalizado == false" class="btn btn-sm btn-light font-weight-bold mr-2" v-b-tooltip.left title="Presione para bloquear" @click.prevent="doToggleFinalizado()"> <i class="fa fa-unlock-alt"></i>Desbloquear</a>
          </span>
          <span v-if="auth.puedeCambiarEstadoFinalizado == false">
            <a href="#" v-if="finalizado == true" class="btn btn-sm btn-light-danger font-weight-bold mr-2"> <i class="fa fa-lock"></i>Finalizado</a>
          </span>

          <a href="#" class="btn btn-sm font-weight-bold mr-2" :class="isDirty ? 'btn-success' : 'btn-light-success'" @click.prevent="doGuardado()"><i class="fa" :class="[ui.estaGuardando ? 'fa-spinner fa-spin p-0 fa-align-center mr-1' : 'fa-save']"></i> Guardar cambios</a>

          <a href="#" class="btn btn-sm btn-light-warning font-weight-bold" @click.prevent="$emit('update:expandirContenido', !expandirContenido)"><i class="fa p-0" :class="[expandirContenido ? 'fa-chevron-up' : 'fa-chevron-down']"></i></a>
        </div>
      </div>
      <div class="card-body" v-show="expandirContenido">
        <form class="form" id="frm-turno-3">
          <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-xl-8">
              <div class="my-5">
                <!-- <h3 class=" text-dark font-weight-bold mb-10">
                  III. Sufragio
                </h3> -->
                <div class="form-group row">
                  <label :class="ui.colLeftClass">
                    6. ¿Se brindó facilidades para el voto de personas con discapacidad y/o mujeres embarazadas?
                  </label>
                  <div :class="ui.colRightClass">
                    <div class="btn-group btn-group" role="group">
                      <button type="button" @click.prevent="actual.t3_FacilidadesPersonasDiscapacidad = true" class="btn" :class="[actual.t3_FacilidadesPersonasDiscapacidad === true ? 'btn-success' : 'btn-outline-secondary']">
                        Sí
                      </button>
                      <button type="button" @click.prevent="actual.t3_FacilidadesPersonasDiscapacidad = false" class="btn" :class="[actual.t3_FacilidadesPersonasDiscapacidad === false ? 'btn-danger' : 'btn-outline-secondary']">
                        No
                      </button>
                    </div>

                    <div v-if="actual.t3_FacilidadesPersonasDiscapacidad == false">
                      <table class="table mb-0 mt-2">
                        <tbody>
                          <tr>
                            <td class="px-0">
                              <label class="checkbox checkbox-danger checkbox-lg">
                                <input type="checkbox" v-model="actual.t3_FacilidadesPersonasDiscapacidadAusenciaEspacio" :value="true" />
                                <span class="mr-2"></span>Ausencia de un espacio habilitado en el primer piso para el sufragio</label
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="px-0">
                              <label class="checkbox checkbox-danger checkbox-lg">
                                <input type="checkbox" v-model="actual.t3_FacilidadesPersonasDiscapacidadSinOrientacion" :value="true" />
                                <span class="mr-2"></span>No se brindó la orientación necesaria a las personas con discapacidad para acercarse a la cabina de votación</label
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="px-0">
                              <label class="checkbox checkbox-danger checkbox-lg">
                                <input type="checkbox" v-model="actual.t3_FacilidadesPersonasDiscapacidadSinAtencion" :value="true" />
                                <span class="mr-2"></span>No se brindó la atención preferencial</label
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="px-0 border-bottom">
                              <label class="checkbox checkbox-danger checkbox-lg">
                                <input type="checkbox" v-model="actual.t3_FacilidadesPersonasDiscapacidadOtro" :value="true" />
                                <span class="mr-2"></span>Otro</label
                              >
                              <textarea class="form-control mt-2" v-if="actual.t3_FacilidadesPersonasDiscapacidadOtro == true" v-model="actual.t3_FacilidadesPersonasDiscapacidadOtroDetalle" type="text" placeholder="Especifique las facilidades ausentes..." required></textarea>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label :class="ui.colLeftClass">
                    7. ¿Algún elector/a tuvo problemas para emitir su voto por causa de género, o por pertenecer a la población indígena y/o LGTBI?
                  </label>
                  <div :class="ui.colRightClass">
                    <div class="btn-group btn-group" role="group">
                      <button type="button" @click.prevent="actual.t3_EmisionVotoProblemasLgtbi = true" class="btn" :class="[actual.t3_EmisionVotoProblemasLgtbi === true ? 'btn-success' : 'btn-outline-secondary']">
                        Sí
                      </button>
                      <button type="button" @click.prevent="actual.t3_EmisionVotoProblemasLgtbi = false" class="btn" :class="[actual.t3_EmisionVotoProblemasLgtbi === false ? 'btn-danger' : 'btn-outline-secondary']">
                        No
                      </button>
                    </div>

                    <b-form-tags v-if="actual.t3_EmisionVotoProblemasLgtbi" class="mt-2" add-button-text="Agregar" duplicate-tag-text="Duplicado(s)" :separator="[' ', ',', ';']" remove-on-delete v-model="actual.t3_IncidentesLgtbinroMesa" placeholder="Indique las mesas..."></b-form-tags>
                    <textarea v-if="actual.t3_EmisionVotoProblemasLgtbi" v-model="actual.t3_IncidentesLgtbinroMesaDetalle" class="form-control mt-2" type="text" placeholder="Explicar brevemente lo ocurrido..." required> </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2"></div>

            <div v-if="eventosActualizacion.length > 0" v-html="eventosActualizacion"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table-hover tbody tr:hover {
  background-color: #f9f9f9;
}
</style>

<script>
export default {
  name: "ficha-turno-3",
  components: {},
  props: {
    localVotacionId: {
      type: Number,
    },
    procesoElectoralId: {
      type: Number,
    },
    expandirContenido: {
      type: Boolean,
    },
    intervaloAutoGuardado: {
      type: Number,
    },
  },
  data: () => ({
    tieneMovimientosRegionales: false,
    finalizado: false,
    auth: {
      autorizadoEdicionTurno: null,
      mensajeNoAutorizadoEdicionTurno: null,
      puedeCambiarEstadoFinalizado: null,
      puedeEditarTurno: null,
      puedeVisualizarTurno: null,
      puedeFinalizarTurno: null,
      puedeReanudarTurno: null,
      fechaInicioTurno: null,
      fechaFinTurno: null,
      fechaInicioEdicionTurno: null,
      fechaFinEdicionTurno: null,
    },
    ui: {
      colLeftClass: "col-sm-12 col-md-5",
      colRightClass: "col-sm-12 col-md-7",
      estaGuardando: false,
      estaCargandoDatos: false,
      tieneCargaInicial: false,
    },
    temporizadorGuardado: null,
    eventosActualizacion: [],
    actual: {
      t3_FacilidadesPersonasDiscapacidad: null,
      t3_FacilidadesPersonasDiscapacidadAusenciaEspacio: null,
      t3_FacilidadesPersonasDiscapacidadSinOrientacion: null,
      t3_FacilidadesPersonasDiscapacidadSinAtencion: null,
      t3_FacilidadesPersonasDiscapacidadOtro: null,
      t3_FacilidadesPersonasDiscapacidadOtroDetalle: null,
      // t3_TienePropagandaElectoralFueraLocal: null,
      // t3_TienePropagandaElectoralDentroLocal: null,
      t3_EmisionVotoProblemasLgtbi: null,
      t3_IncidentesLgtbinroMesa: null,
      t3_IncidentesLgtbinroMesaDetalle: null,
      // itemsPropagandaFuera: [],
      // itemsPropagandaDentro: [],
      itemsIncidenteLGTBI: [],
    },
    guardado: {
      t3_FacilidadesPersonasDiscapacidad: null,
      t3_FacilidadesPersonasDiscapacidadAusenciaEspacio: null,
      t3_FacilidadesPersonasDiscapacidadSinOrientacion: null,
      t3_FacilidadesPersonasDiscapacidadSinAtencion: null,
      t3_FacilidadesPersonasDiscapacidadOtro: null,
      t3_FacilidadesPersonasDiscapacidadOtroDetalle: null,
      // t3_TienePropagandaElectoralFueraLocal: null,
      // t3_TienePropagandaElectoralDentroLocal: null,
      t3_EmisionVotoProblemasLgtbi: null,
      t3_IncidentesLgtbinroMesa: null,
      t3_IncidentesLgtbinroMesaDetalle: null,
      // itemsPropagandaFuera: [],
      // itemsPropagandaDentro: [],
      itemsIncidenteLGTBI: [],
    },
    partidos: [] /*Seccion del turno 4 que ahora pertenece al turno 3*/,
  }),
  computed: {
    isDirty: function() {
      //return JSON.stringify(this.actual) != JSON.stringify(this.guardado);
      return false;
    },
    mostrarDetalleFicha: function() {
      if (!this.ui.tieneCargaInicial) return false;
      if (!this.auth.autorizadoEdicionTurno) return false;

      return true;
    },
  },
  methods: {
    // fetchPartidosPoliticos: function() {
    //   this.$http
    //     .get(this.apiRoute("Parameter", "ListParametrosPoliticos"), {
    //       params: {
    //         Id: this.localVotacionId,
    //       },
    //     })
    //     .then(function(response) {
    //       var parameterResponse = response.body.data;
    //       this.partidos = parameterResponse;
    //       debugger;
    //       this.tieneMovimientosRegionales = this.partidos.find((x)=> x.regionId !== 0);

    //     });
    // },
    // getImagenPartidoPolitico: function(partidoPoliticoParamId) {
    //   var partido = this.partidos.find((x) => x.parametroId == partidoPoliticoParamId);
    //   var imagen = partido ? partido.imagen : null;

    //   if (imagen == null) {
    //     imagen = "app-partidos/_no-image.png";
    //   }

    //   return `${this.apiResource(imagen)}`;
    // },
    // addItemPropaganda: function(list) {
    //   list.push({
    //     localVotacionFichaPropagandaElectoralId: null,
    //     partidoPoliticoParamId: null,
    //     tieneAfichePegatina: false,
    //     tienePintasCartelesGrandes: false,
    //     tieneMilitantesConDistintivos: false,
    //     tieneOtro: false,
    //     descripcionOtro: null,
    //   });
    // },
    // deleteItemPropagandaFuera: async function(item) {
    //   if (await this.sweetConfirm("¿Confirma que se debe eliminar este elemento?")) {
    //     this.actual.itemsPropagandaFuera = this.removeArrayItem(this.actual.itemsPropagandaFuera, item);
    //   }
    // },
    // deleteItemPropagandaDentro: async function(item) {
    //   if (await this.sweetConfirm("¿Confirma que se debe eliminar este elemento?")) {
    //     this.actual.itemsPropagandaDentro = this.removeArrayItem(this.actual.itemsPropagandaDentro, item);
    //   }
    // },
    /*=========================================================================================================*/
    inicializarGuardado: function() {
      // var vm = this;
      // clearTimeout(this.temporizadorGuardado);
      // this.temporizadorGuardado = setTimeout(function() {
      //   if (vm.isDirty) {
      //     vm.doGuardado();
      //   }
      // }, this.intervaloAutoGuardado);
    },
    doCargarFicha: async function(omitirNoGuardados) {
      this.ui.estaCargandoDatos = true;
      var model = {
        localVotacionId: this.localVotacionId,
        procesoElectoralId: this.procesoElectoralId,
      };

      await this.$http.post(this.apiRoute("Sheet", "GetFichaTurnoTres"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          var ficha = response.body.data.ficha;

          ficha.t3_IncidentesLgtbinroMesa = ficha.t3_IncidentesLgtbinroMesa ? ficha.t3_IncidentesLgtbinroMesa.split("|") : [];

          //  var tempFicha = this.cloneObject(ficha);
          var tempActual = this.cloneObject(this.actual);
          var tempGuardado = this.cloneObject(this.guardado);

          tempActual.t3_FacilidadesPersonasDiscapacidad = this.actualizarBaseDiferente("t3_FacilidadesPersonasDiscapacidad", tempActual, tempGuardado, ficha);
          tempActual.t3_FacilidadesPersonasDiscapacidadAusenciaEspacio = this.actualizarBaseDiferente("t3_FacilidadesPersonasDiscapacidadAusenciaEspacio", tempActual, tempGuardado, ficha);
          tempActual.t3_FacilidadesPersonasDiscapacidadSinOrientacion = this.actualizarBaseDiferente("t3_FacilidadesPersonasDiscapacidadSinOrientacion", tempActual, tempGuardado, ficha);
          tempActual.t3_FacilidadesPersonasDiscapacidadSinAtencion = this.actualizarBaseDiferente("t3_FacilidadesPersonasDiscapacidadSinAtencion", tempActual, tempGuardado, ficha);
          tempActual.t3_FacilidadesPersonasDiscapacidadOtro = this.actualizarBaseDiferente("t3_FacilidadesPersonasDiscapacidadOtro", tempActual, tempGuardado, ficha);
          tempActual.t3_FacilidadesPersonasDiscapacidadOtroDetalle = this.actualizarBaseDiferente("t3_FacilidadesPersonasDiscapacidadOtroDetalle", tempActual, tempGuardado, ficha);
          tempActual.t3_EmisionVotoProblemasLgtbi = this.actualizarBaseDiferente("t3_EmisionVotoProblemasLgtbi", tempActual, tempGuardado, ficha);
          tempActual.t3_IncidentesLgtbinroMesa = this.actualizarBaseDiferente("t3_IncidentesLgtbinroMesa", tempActual, tempGuardado, ficha);
          tempActual.t3_IncidentesLgtbinroMesaDetalle = this.actualizarBaseDiferente("t3_IncidentesLgtbinroMesaDetalle", tempActual, tempGuardado, ficha);

          // if (!omitirNoGuardados) {
          //   for (let i = 0; i < tempActual.itemsPropagandaFuera.length; i++) {
          //     if (!tempActual.itemsPropagandaFuera[i].localVotacionFichaPropagandaElectoralId) {
          //       //aún no está guardado
          //       tempFicha.itemsPropagandaFuera.push(tempActual.itemsPropagandaFuera[i]);
          //     }
          //   }

          //   for (let i = 0; i < tempActual.itemsPropagandaDentro.length; i++) {
          //     if (!tempActual.itemsPropagandaDentro[i].localVotacionFichaPropagandaElectoralId) {
          //       //aún no está guardado
          //       tempFicha.itemsPropagandaDentro.push(tempActual.itemsPropagandaDentro[i]);
          //     }
          //   }
          // }

          // tempActual.t3_TienePropagandaElectoralFueraLocal = this.actualizarBaseDiferente("t3_TienePropagandaElectoralFueraLocal", tempActual, tempGuardado, ficha);
          // tempActual.t3_TienePropagandaElectoralDentroLocal = this.actualizarBaseDiferente("t3_TienePropagandaElectoralDentroLocal", tempActual, tempGuardado, ficha);
          /*===============================*/

          //this.actual = this.cloneObject(tempActual);
          this.actual = this.cloneObject(ficha);
          this.guardado = this.cloneObject(ficha);
          this.auth = response.body.data.auth;
          //this.inicializarGuardado();
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.ui.tieneCargaInicial = true;
      this.ui.estaCargandoDatos = false;
    },
    actualizarBaseDiferente: function(propiedad, fichaActual, fichaGuardado, fichaActualizada) {
      var valorActual = JSON.stringify(fichaActual[propiedad]);
      var valorGuardado = JSON.stringify(fichaGuardado[propiedad]);
      var valorActualizado = JSON.stringify(fichaActualizada[propiedad]);

      if (valorActual == valorGuardado && valorGuardado != valorActualizado) {
        //this.eventosActualizacion.push("<br>" + propiedad + " se actualiza: " + valorActual + " -> " + valorActualizado);
        return fichaActualizada[propiedad];
      } else {
        //this.eventosActualizacion.push("<br>" + propiedad + " se mantiene: " + valorActual);
        return fichaActual[propiedad];
      }
    },
    doGuardado: async function(omitirNotificacion) {
      if (this.ui.estaGuardando) return;

      if (!this.isFormValid("frm-turno-3")) {
        if (omitirNotificacion != true) {
          //return this.sweetAlert("Hay campos con valores incorrectos. Revise los datos ingresados.");
          this.$bvToast.toast("Hay campos con valores incorrectos. Revise los datos ingresados.", {
            title: "Turno 3",
            autoHideDelay: 5000,
            variant: "warning",
            appendToast: false,
            solid: true,
          });
          return false;
        }
      }

      var actualClone = this.cloneObject(this.actual);

      var model = this.cloneObject(this.actual);
      model.localVotacionId = this.localVotacionId;
      model.procesoElectoralId = this.procesoElectoralId;
      model.t3_IncidentesLgtbinroMesa = model.t3_IncidentesLgtbinroMesa ? model.t3_IncidentesLgtbinroMesa.join("|") : [];

      var guardadoExistoso = false;
      this.ui.estaGuardando = true;
      await this.$http.post(this.apiRoute("Sheet", "EditFichaTurnoTres"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          this.guardado = actualClone;

          //this.sweetAlert(response.body.message, "success");

          if (omitirNotificacion != true) {
            this.$bvToast.toast("Los cambios se han guardado correctamente.", {
              title: "Turno 3",
              autoHideDelay: 5000,
              variant: "success",
              appendToast: false,
              solid: true,
            });
          }
          guardadoExistoso = true;

          this.doCargarFicha(true);

          //Se envía al hub:
          this.$notifyHub.notificarCambioFicha(this.procesoElectoralId, this.localVotacionId, 3);
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.ui.estaGuardando = false;
      return guardadoExistoso;
    },
    doToggleFinalizado: async function() {
      if (this.finalizado) {
        if (!(await this.sweetConfirm("¿Desea desbloquear esta sección?"))) {
          return;
        }
        this.finalizado = !this.finalizado;
      } else {
        if (!(await this.sweetConfirm("¿Desea finalizar esta sección?"))) {
          return;
        }
        this.finalizado = !this.finalizado;
      }
    },
  },
  watch: {
    isDirty: function(newValue, oldValue) {
      // if (newValue && this.intervaloAutoGuardado) {
      //   this.inicializarGuardado();
      // } else {
      //   clearTimeout(this.temporizadorGuardado);
      // }
    },
    actual: {
      deep: true,
       handler() {
      //   if (this.intervaloAutoGuardado && !this.ui.estaCargandoDatos) {
      //     this.inicializarGuardado();
      //     //this.eventosActualizacion.push("<br>WATCH ACTUAL");
      //   }
       },
    },
  },
  mounted: function() {
    this.initParsley();
    //this.fetchPartidosPoliticos(); 
    this.doCargarFicha();
  },
  destoyed: function() {
    //clearTimeout(this.temporizadorGuardado);
  },
};
</script>
