<template>
  <div>
    <div class="card card-custom card-sticky" v-if="!mostrarDetalleFicha">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label cursor-pointer">
            <span class="label label-xl label-dot label-info mr-2"></span> Turno 4 <i class="mr-2"></i>
            <small v-if="ui.estaCargandoDatos" class="">Cargando datos de la ficha...</small>
            <small v-else-if="auth.mensajeNoAutorizadoEdicionTurno" class="text-danger">{{ auth.mensajeNoAutorizadoEdicionTurno }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <a href="#" class="btn btn-sm btn-light font-weight-bold" @click.prevent="doCargarFicha()"><i class="fa p-0 fa-sync" :class="[ui.estaCargandoDatos ? 'fa-spin ' : '']"></i></a>
        </div>
      </div>
    </div>
    <div class="card card-custom card-sticky" v-if="mostrarDetalleFicha">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label cursor-pointer" @click.prevent="$emit('update:expandirContenido', !expandirContenido)">
            <span class="label label-xl label-dot label-info mr-2"></span> Turno 4 <i class="mr-2"></i>
            <small class="">{{ auth.fechaInicioTurno | time }} - {{ auth.fechaFinTurno | time }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <span v-if="auth.puedeCambiarEstadoFinalizado == true">
            <a href="#" v-if="finalizado == true" class="btn btn-sm btn-light-danger font-weight-bold mr-2" v-b-tooltip.left title="Presione para desbloquear" @click.prevent="doToggleFinalizado()"> <i class="fa fa-lock"></i>Finalizado</a>
            <a href="#" v-if="finalizado == false" class="btn btn-sm btn-light font-weight-bold mr-2" v-b-tooltip.left title="Presione para bloquear" @click.prevent="doToggleFinalizado()"> <i class="fa fa-unlock-alt"></i>Desbloquear</a>
          </span>
          <span v-if="auth.puedeCambiarEstadoFinalizado == false">
            <a href="#" v-if="finalizado == true" class="btn btn-sm btn-light-danger font-weight-bold mr-2"> <i class="fa fa-lock"></i>Finalizado</a>
          </span>

          <a href="#" class="btn btn-sm font-weight-bold mr-2" :class="isDirty ? 'btn-success' : 'btn-light-success'" @click.prevent="doGuardado()"><i class="fa" :class="[ui.estaGuardando ? 'fa-spinner fa-spin p-0 fa-align-center mr-1' : 'fa-save']"></i> Guardar cambios</a>

          <a href="#" class="btn btn-sm btn-light-warning font-weight-bold" @click.prevent="$emit('update:expandirContenido', !expandirContenido)"><i class="fa p-0" :class="[expandirContenido ? 'fa-chevron-up' : 'fa-chevron-down']"></i></a>
        </div>
      </div>
      <div class="card-body" v-show="expandirContenido">
        <form class="form" id="frm-turno-4">
          <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-xl-8">
              <div class="my-5">
                <!--Seccion del turno 3 que ahora pertenece al turno 4-->
                <h3 class=" text-dark font-weight-bold mb-10">V. Cierre del local e inicio del escrutinio</h3>
                <div class="form-group row">
                  <label :class="ui.colLeftClass">8. ¿El local de votación cerró a las 5:00 pm?</label>
                  <div :class="ui.colRightClass">
                    <div class="btn-group btn-group" role="group">
                      <button type="button" @click.prevent="actual.t4LocalCerro500Pm = true" class="btn" :class="[actual.t4LocalCerro500Pm === true ? 'btn-success' : 'btn-outline-secondary']">Sí</button>
                      <button type="button" @click.prevent="actual.t4LocalCerro500Pm = false" class="btn" :class="[actual.t4LocalCerro500Pm === false ? 'btn-danger' : 'btn-outline-secondary']">No</button>
                    </div>
                    <textarea v-if="actual.t4LocalCerro500Pm === false" v-model="actual.t4LocalCerro500Pmdetalle" class="form-control mt-2" type="text" placeholder="Señale brevemente por qué:..." required> </textarea>
                  </div>
                </div>

                <!-- 19. ¿Qué porcentaje aproximado de mesas inició con el escrutinio? -->
                <div class="form-group row">
                  <label :class="ui.colLeftClass">9. ¿Qué porcentaje aproximado de mesas inició con el escrutinio?</label>
                  <div :class="ui.colRightClass">
                    <div class="btn-group btn-group" role="group">
                      <button type="button" @click.prevent="actual.t4PorcentajeMesasInicioEscrutinios = '0-50'" class="btn" :class="[actual.t4PorcentajeMesasInicioEscrutinios === '0-50' ? 'btn-primary' : 'btn-outline-secondary']">Menos del 50%</button>
                      <button type="button" @click.prevent="actual.t4PorcentajeMesasInicioEscrutinios = '50-75'" class="btn" :class="[actual.t4PorcentajeMesasInicioEscrutinios === '50-75' ? 'btn-primary' : 'btn-outline-secondary']">De 50 a 75%</button>
                      <button type="button" @click.prevent="actual.t4PorcentajeMesasInicioEscrutinios = '75-100'" class="btn" :class="[actual.t4PorcentajeMesasInicioEscrutinios === '75-100' ? 'btn-primary' : 'btn-outline-secondary']">De 75 a 100%</button>
                    </div>
                    <div class="form-text text-muted">
                      Considere el estado a las 5:00pm.
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group row">
                  <label :class="ui.colLeftClass">17. Hay presencia de:</label>
                  <div :class="ui.colRightClass">
                    <div class="btn-group btn-group mb-2" role="group">
                      <button type="button" @click.prevent="actual.t4PresenciaOnpe = !actual.t4PresenciaOnpe" class="btn" :class="[actual.t4PresenciaOnpe ? 'btn-primary' : 'btn-outline-secondary']">ONPE</button>
                      <button type="button" @click.prevent="actual.t4PresenciaJne = !actual.t4PresenciaJne" class="btn" :class="[actual.t4PresenciaJne ? 'btn-primary' : 'btn-outline-secondary']">JNE</button>
                      <button type="button" @click.prevent="actual.t4PresenciaFfaapnp = !actual.t4PresenciaFfaapnp" class="btn" :class="[actual.t4PresenciaFfaapnp ? 'btn-primary' : 'btn-outline-secondary']">FFAA / PNP</button>
                      <button type="button" @click.prevent="actual.t4PresenciaMinisterioPublico = !actual.t4PresenciaMinisterioPublico" class="btn" :class="[actual.t4PresenciaMinisterioPublico ? 'btn-primary' : 'btn-outline-secondary']">Ministerio Público</button>
                      <button type="button" @click.prevent="actual.t4PresenciaDefensoriaDelPueblo = !actual.t4PresenciaDefensoriaDelPueblo" class="btn" :class="[actual.t4PresenciaDefensoriaDelPueblo ? 'btn-primary' : 'btn-outline-secondary']">Defensoría del pueblo</button>
                    </div>
                    <div class="text-muted">Otros:</div>
                    <textarea v-model="actual.t4PresenciaOtros" class="form-control mt-2" type="text" placeholder="Indicar el nombre de la entidad..."></textarea>
                  </div>
                </div> -->
                <div class="form-group row">
                  <label :class="ui.colLeftClass">10. Personeros en el local de observación</label>
                  <div :class="ui.colRightClass">
                    <div class="btn-group btn-group" role="group">
                      <button type="button" @click.prevent="actual.t4TienePersoneros = true" class="btn" :class="[actual.t4TienePersoneros === true ? 'btn-success' : 'btn-outline-secondary']">
                        Sí
                      </button>
                      <button type="button" @click.prevent="actual.t4TienePersoneros = false" class="btn" :class="[actual.t4TienePersoneros === false ? 'btn-danger' : 'btn-outline-secondary']">
                        No
                      </button>
                    </div>
                    <div v-if="actual.t4TienePersoneros" class="mt-2">
                      <div class="card-body py-0">
                        <table class="table mb-0">
                          <tbody>
                            <template v-for="(item, index) in actual.itemsPersoneros">
                              <tr :key="index">
                                <td>
                                  <img class="symbol-label" :src="getImagenPartidoPolitico(item.organizacionPoliticaParamId)" style="width:40px;height:auto;" />
                                </td>
                                <td class="w-75">
                                  <select class="form-control" v-model="item.organizacionPoliticaParamId" required data-parsley-errors-container="#turno-4-msj-error-personer">
                                    <option :value="null">- seleccione partido -</option>
                                    <optgroup label="Partidos Políticos">
                                      <template v-for="partido in partidos">
                                        <option :key="partido.parametroId" :value="partido.parametroId" v-if="partido.regionId == 0">{{ partido.descripcion }}</option>
                                      </template>
                                    </optgroup>
                                    <optgroup label="Movimientos Regionales">
                                      <template v-for="partido in partidos">
                                        <option :key="partido.parametroId" :value="partido.parametroId" v-if="partido.regionId != 0">{{ partido.descripcion }}</option>
                                      </template>
                                    </optgroup>
                                  </select>
                                  <!-- <b-form-tags class="mt-2" add-button-text="Agregar" duplicate-tag-text="Duplicado(s)" :separator="[' ', ',', ';', '|']" remove-on-delete v-model="item.mesas" placeholder="Cantidad de personeros..." ></b-form-tags> -->
                                  <input type="number" min="1" max="99" class="form-control mt-2 w-50" v-model="item.numero" placeholder="Cantidad de personeros..."/>
                                </td>
                                <td>
                                  <a href="#" class="btn btn-outline-secondary" @click.prevent="deleteItemPersonero(item)"><i class="fa fa-trash p-0 text-danger"></i></a>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <a href="#" class="btn btn-secondary " @click.prevent="addItemPersonero(actual.itemsPersoneros)"><i class="fa fa-plus"></i> Agregar personero</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label :class="ui.colLeftClass">11. Se registró algún incidente durante el escrutinio:</label>
                  <div :class="ui.colRightClass">
                    <div class="btn-group btn-group" role="group">
                      <button type="button" @click.prevent="actual.t4TieneIncidenteDuranteEscrutinio = true" class="btn" :class="[actual.t4TieneIncidenteDuranteEscrutinio === true ? 'btn-success' : 'btn-outline-secondary']">Sí</button>
                      <button type="button" @click.prevent="actual.t4TieneIncidenteDuranteEscrutinio = false" class="btn" :class="[actual.t4TieneIncidenteDuranteEscrutinio === false ? 'btn-danger' : 'btn-outline-secondary']">No</button>
                    </div>
                    <textarea v-if="actual.t4TieneIncidenteDuranteEscrutinio" v-model="actual.t4IncidenteDuranteEscrutinioDetalle" class="form-control mt-2" type="text" placeholder="Indicar el detalle..." required> </textarea>
                    <textarea v-if="actual.t4TieneIncidenteDuranteEscrutinio" v-model="actual.t4IncidenteDuranteEscrutinioMedidas" class="form-control mt-2" type="text" placeholder="Indicar las medidas..." required> </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table-hover tbody tr:hover {
  background-color: #f9f9f9;
}
</style>

<script>
export default {
  name: "ficha-turno-4",
  components: {},
  props: {
    localVotacionId: {
      type: Number,
    },
    procesoElectoralId: {
      type: Number,
    },
    expandirContenido: {
      type: Boolean,
    },
    intervaloAutoGuardado: {
      type: Number,
    },
  },
  data: () => ({
    finalizado: false,
    auth: {
      autorizadoEdicionTurno: null,
      mensajeNoAutorizadoEdicionTurno: null,
      puedeCambiarEstadoFinalizado: null,
      puedeEditarTurno: null,
      puedeVisualizarTurno: null,
      puedeFinalizarTurno: null,
      puedeReanudarTurno: null,
      fechaInicioTurno: null,
      fechaFinTurno: null,
      fechaInicioEdicionTurno: null,
      fechaFinEdicionTurno: null,
    },
    ui: {
      colLeftClass: "col-sm-12 col-md-5",
      colRightClass: "col-sm-12 col-md-7",
      estaGuardando: false,
      estaCargandoDatos: false,
      tieneCargaInicial: false,
    },
    temporizadorGuardado: null,
    eventosActualizacion: [],
    actual: {
      t4TienePersoneros: null,
      t4LocalCerro500Pm: null /* Bool */,
      t4LocalCerro500Pmdetalle: null /* Bool */,
      t4PorcentajeMesasInicioEscrutinios: null /* Bool */,
      // t4PresenciaOnpe: null /* Bool */,
      // t4PresenciaJne: null /* Bool */,
      // t4PresenciaFfaapnp: null /* String */,
      // t4PresenciaMinisterioPublico: null /* Bool */,
      // t4PresenciaDefensoriaDelPueblo: null /* Bool */,
      // t4PresenciaOtros: null /* String */,
      t4TieneIncidenteDuranteEscrutinio: null /* String */,
      t4IncidenteDuranteEscrutinioDetalle: null /* String */,
      t4IncidenteDuranteEscrutinioMedidas: null /* String */,
      itemsPersoneros: [],
    },
    guardado: {
      t4TienePersoneros: null,
      t4LocalCerro500Pm: null /* Bool */,
      t4LocalCerro500Pmdetalle: null /* Bool */,
      t4PorcentajeMesasInicioEscrutinios: null /* Bool */,
      // t4PresenciaOnpe: null /* Bool */,
      // t4PresenciaJne: null /* Bool */,
      // t4PresenciaFfaapnp: null /* String */,
      // t4PresenciaMinisterioPublico: null /* Bool */,
      // t4PresenciaDefensoriaDelPueblo: null /* Bool */,
      // t4PresenciaOtros: null /* String */,
      t4TieneIncidenteDuranteEscrutinio: null /* String */,
      t4IncidenteDuranteEscrutinioDetalle: null /* String */,
      t4IncidenteDuranteEscrutinioMedidas: null /* String */,
      itemsPersoneros: [],
    },

    partidos: [],
  }),
  computed: {
    isDirty: function() {
      return JSON.stringify(this.actual) != JSON.stringify(this.guardado);
    },
    mostrarDetalleFicha: function() {
      if (!this.ui.tieneCargaInicial) return false;
      if (!this.auth.autorizadoEdicionTurno) return false;

      return true;
    },
  },
  methods: {
    fetchPartidosPoliticos: function() {
      this.$http
        .get(this.apiRoute("Parameter", "ListParametrosPoliticos"), {
          params: {
            Id: this.localVotacionId,
          },
        })
        .then(function(response) {
          var parameterResponse = response.body.data;
          this.partidos = parameterResponse;
        });
    },
    getImagenPartidoPolitico: function(partidoPoliticoParamId) {
      var partido = this.partidos.find((x) => x.parametroId == partidoPoliticoParamId);
      var imagen = partido ? partido.imagen : null;

      if (imagen == null) {
        imagen = "app-partidos/_no-image.png";
      }

      return `${this.apiResource(imagen)}`;
    },
    addItemPersonero: function(list) {
      list.push({
        localVotacionFichaPersoneroId: null,
        numero: 1,
        organizacionPoliticaParamId: null,
      });
    },
    deleteItemPersonero: async function(item) {
      if (await this.sweetConfirm("¿Confirma que se debe eliminar este elemento?")) {
        this.actual.itemsPersoneros = this.removeArrayItem(this.actual.itemsPersoneros, item);
      }
    },
    inicializarGuardado: function() {
      // var vm = this;
      // clearTimeout(this.temporizadorGuardado);
      // this.temporizadorGuardado = setTimeout(function() {
      //   if (vm.isDirty) {
      //     vm.doGuardado();
      //   }
      // }, this.intervaloAutoGuardado);
    },
    doCargarFicha: async function() {
      this.ui.estaCargandoDatos = true;
      var model = {
        localVotacionId: this.localVotacionId,
        procesoElectoralId: this.procesoElectoralId,
      };

      await this.$http.post(this.apiRoute("Sheet", "GetFichaTurnoCuatro"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          var ficha = response.body.data.ficha;

          // if (ficha.itemsPersoneros.length > 0) {
          //   for (var i = 0; i < ficha.itemsPersoneros.length; i++) {
          //     ficha.itemsPersoneros[i].numero = ficha.itemsPersoneros[i].numero ? ficha.itemsPersoneros[i].numero.split("|") : [];
          //   }
          // }

          var tempActual = this.cloneObject(this.actual);
          var tempGuardado = this.cloneObject(this.guardado);

          tempActual.t4LocalCerro500Pm = this.actualizarBaseDiferente("t4LocalCerro500Pm", tempActual, tempGuardado, ficha);
          tempActual.t4LocalCerro500Pmdetalle = this.actualizarBaseDiferente("t4LocalCerro500Pmdetalle", tempActual, tempGuardado, ficha);
          tempActual.t4PorcentajeMesasInicioEscrutinios = this.actualizarBaseDiferente("t4PorcentajeMesasInicioEscrutinios", tempActual, tempGuardado, ficha);
          // tempActual.t4PresenciaOnpe = this.actualizarBaseDiferente("t4PresenciaOnpe", tempActual, tempGuardado, ficha);
          // tempActual.t4PresenciaJne = this.actualizarBaseDiferente("t4PresenciaJne", tempActual, tempGuardado, ficha);
          // tempActual.t4PresenciaFfaapnp = this.actualizarBaseDiferente("t4PresenciaFfaapnp", tempActual, tempGuardado, ficha);
          // tempActual.t4PresenciaMinisterioPublico = this.actualizarBaseDiferente("t4PresenciaMinisterioPublico", tempActual, tempGuardado, ficha);
          // tempActual.t4PresenciaDefensoriaDelPueblo = this.actualizarBaseDiferente("t4PresenciaDefensoriaDelPueblo", tempActual, tempGuardado, ficha);
          // tempActual.t4PresenciaOtros = this.actualizarBaseDiferente("t4PresenciaOtros", tempActual, tempGuardado, ficha);
          tempActual.t4TienePersoneros = this.actualizarBaseDiferente("t4TienePersoneros", tempActual, tempGuardado, ficha);
          tempActual.t4TieneIncidenteDuranteEscrutinio = this.actualizarBaseDiferente("t4TieneIncidenteDuranteEscrutinio", tempActual, tempGuardado, ficha);
          tempActual.t4IncidenteDuranteEscrutinioDetalle = this.actualizarBaseDiferente("t4IncidenteDuranteEscrutinioDetalle", tempActual, tempGuardado, ficha);
          tempActual.t4IncidenteDuranteEscrutinioMedidas = this.actualizarBaseDiferente("t4IncidenteDuranteEscrutinioMedidas", tempActual, tempGuardado, ficha);
          tempActual.itemsPersoneros = this.actualizarBaseDiferente("itemsPersoneros", tempActual, tempGuardado, ficha);

          this.actual = this.cloneObject(tempActual);
          this.guardado = this.cloneObject(ficha);
          this.auth = response.body.data.auth;

          //this.inicializarGuardado();
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.ui.tieneCargaInicial = true;
      this.ui.estaCargandoDatos = false;
    },
    actualizarBaseDiferente: function(propiedad, fichaActual, fichaGuardado, fichaActualizada) {
      var valorActual = JSON.stringify(fichaActual[propiedad]);
      var valorGuardado = JSON.stringify(fichaGuardado[propiedad]);
      var valorActualizado = JSON.stringify(fichaActualizada[propiedad]);

      if (valorActual == valorGuardado && valorGuardado != valorActualizado) {
        //this.eventosActualizacion.push("<br>" + propiedad + " se actualiza: " + valorActual + " -> " + valorActualizado);
        return fichaActualizada[propiedad];
      } else {
        //this.eventosActualizacion.push("<br>" + propiedad + " se mantiene: " + valorActual);
        return fichaActual[propiedad];
      }
    },
    doGuardado: async function(omitirNotificacion) {
      if (this.ui.estaGuardando) return;

      if (!this.isFormValid("frm-turno-4")) {
        if (omitirNotificacion != true) {
          //return this.sweetAlert("Hay campos con valores incorrectos. Revise los datos ingresados.");
          this.$bvToast.toast("Hay campos con valores incorrectos. Revise los datos ingresados.", {
            title: "Turno 4",
            autoHideDelay: 5000,
            variant: "warning",
            appendToast: false,
            solid: true,
          });
          return false;
        }
      }

      var actualClone = this.cloneObject(this.actual);
      var model = this.cloneObject(this.actual);
      model.localVotacionId = this.localVotacionId;
      model.procesoElectoralId = this.procesoElectoralId;

      // if (model.itemsPersoneros.length > 0) {
      //   for (var i = 0; i < model.itemsPersoneros.length; i++) {
      //     model.itemsPersoneros[i].mesas = model.itemsPersoneros[i].mesas ? model.itemsPersoneros[i].mesas.join("|") : [];
      //   }
      // }

      var guardadoExistoso = false;
      this.ui.estaGuardando = true;
      await this.$http.post(this.apiRoute("Sheet", "EditFichaTurnoCuatro"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          this.guardado = actualClone;

          //this.sweetAlert(response.body.message, "success");

          if (omitirNotificacion != true) {
            this.$bvToast.toast("Los cambios se han guardado correctamente.", {
              title: "Turno 4",
              autoHideDelay: 5000,
              variant: "success",
              appendToast: false,
              solid: true,
            });
          }
          guardadoExistoso = true;

          this.doCargarFicha();

          //Se envía al hub:
          this.$notifyHub.notificarCambioFicha(this.procesoElectoralId, this.localVotacionId, 4);
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.ui.estaGuardando = false;
      return guardadoExistoso;
    },
    doToggleFinalizado: async function() {
      if (this.finalizado) {
        if (!(await this.sweetConfirm("¿Desea desbloquear esta sección?"))) {
          return;
        }
        this.finalizado = !this.finalizado;
      } else {
        if (!(await this.sweetConfirm("¿Desea finalizar esta sección?"))) {
          return;
        }
        this.finalizado = !this.finalizado;
      }
    },
  },
  watch: {
    isDirty: function(newValue, oldValue) {
      // if (newValue && this.intervaloAutoGuardado) {
      //   this.inicializarGuardado();
      // } else {
      //   clearTimeout(this.temporizadorGuardado);
      // }
    },
    actual: {
      deep: true,
      handler() {
        // if (this.intervaloAutoGuardado && !this.ui.estaCargandoDatos) {
        //   this.inicializarGuardado();
        //   //this.eventosActualizacion.push("<br>WATCH ACTUAL");
        // }
      },
    },
  },
  mounted: function() {
    this.initParsley();
    this.fetchPartidosPoliticos();
    this.doCargarFicha();
  },
  destoyed: function() {
   // clearTimeout(this.temporizadorGuardado);
  },
};
</script>
