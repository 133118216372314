<template>
  <div>
    <div class="card card-custom card-sticky" v-if="!mostrarDetalleFicha">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label cursor-pointer">
            <span class="label label-xl label-dot label-primary mr-2"></span> Turno 1 <i class="mr-2"></i>
            <small v-if="ui.estaCargandoDatos" class="">Cargando datos de la ficha...</small>
            <small v-else-if="auth.mensajeNoAutorizadoEdicionTurno" class="text-danger">{{ auth.mensajeNoAutorizadoEdicionTurno }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <a href="#" class="btn btn-sm btn-light font-weight-bold" @click.prevent="doCargarFicha()"><i class="fa p-0 fa-sync" :class="[ui.estaCargandoDatos ? 'fa-spin ' : '']"></i></a>
        </div>
      </div>
    </div>
    <div class="card card-custom card-sticky" v-if="mostrarDetalleFicha">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label cursor-pointer" @click.prevent="$emit('update:expandirContenido', !expandirContenido)">
            <span class="label label-xl label-dot label-primary mr-2"></span> Turno 1 <i class="mr-2"></i>
            <small class="">{{ auth.fechaInicioTurno | time }} - {{ auth.fechaFinTurno | time }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <span v-if="auth.puedeCambiarEstadoFinalizado == true">
            <a href="#" v-if="finalizado == true" class="btn btn-sm btn-light-danger font-weight-bold mr-2" v-b-tooltip.left title="Presione para desbloquear" @click.prevent="doToggleFinalizado()"> <i class="fa fa-lock"></i>Finalizado</a>
            <a href="#" v-if="finalizado == false" class="btn btn-sm btn-light font-weight-bold mr-2" v-b-tooltip.left title="Presione para bloquear" @click.prevent="doToggleFinalizado()"> <i class="fa fa-unlock-alt"></i>Desbloquear</a>
          </span>
          <span v-if="auth.puedeCambiarEstadoFinalizado == false">
            <a href="#" v-if="finalizado == true" class="btn btn-sm btn-light-danger font-weight-bold mr-2"> <i class="fa fa-lock"></i>Finalizado</a>
          </span>
          <div class="w-100">
            <a href="#" class="btn btn-sm font-weight-bold mr-2" :class="isDirty ? 'btn-success' : 'btn-light-success'" @click.prevent="doGuardado()"><i class="fa" :class="[ui.estaGuardando ? 'fa-spinner fa-spin p-0 fa-align-center mr-1' : 'fa-save']"></i> Guardar cambios</a>
            <a href="#" class="btn btn-sm btn-light-warning font-weight-bold" @click.prevent="$emit('update:expandirContenido', !expandirContenido)"><i class="fa p-0" :class="[expandirContenido ? 'fa-chevron-up' : 'fa-chevron-down']"></i></a>
          </div>
        </div>
      </div>
      <div class="card-body" v-show="expandirContenido">
        <form class="form" id="frm-turno-1">
          <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-xl-8">
              <div class="my-5">
                <h3 class=" text-dark font-weight-bold mb-10">I. Instalación de mesa y actores electorales</h3>
                <!-- 1. ¿Qué porcentaje aproximado de mesas se instaló en los siguientes horarios? -->
                <div class="form-group row">
                  <label :class="ui.colLeftClass">1. ¿Qué porcentaje aproximado de mesas se instaló en los siguientes horarios?</label>
                  <div :class="ui.colRightClass">
                    <div class="btn-group btn-group" role="group">
                      <button type="button" @click.prevent="actual.t1_PorcentajeMesasInstaladas = '0-50'" class="btn" :class="[actual.t1_PorcentajeMesasInstaladas === '0-50' ? 'btn-primary' : 'btn-outline-secondary']">Menos del 50%</button>
                      <button type="button" @click.prevent="actual.t1_PorcentajeMesasInstaladas = '50-75'" class="btn" :class="[actual.t1_PorcentajeMesasInstaladas === '50-75' ? 'btn-primary' : 'btn-outline-secondary']">De 50 a 75%</button>
                      <button type="button" @click.prevent="actual.t1_PorcentajeMesasInstaladas = '75-100'" class="btn" :class="[actual.t1_PorcentajeMesasInstaladas === '75-100' ? 'btn-primary' : 'btn-outline-secondary']">De 75 a 100%</button>
                    </div>
                    <div class="form-text text-muted">
                      Considere solo el horario de 07:00 - 09:20.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label :class="ui.colLeftClass">2. Hay presencia de:</label>
                  <div :class="ui.colRightClass">
                    <div class="btn-group btn-group mb-2" role="group">
                      <button type="button" @click.prevent="actual.t4PresenciaOnpe = !actual.t4PresenciaOnpe" class="btn" :class="[actual.t4PresenciaOnpe ? 'btn-primary' : 'btn-outline-secondary']">ONPE</button>
                      <button type="button" @click.prevent="actual.t4PresenciaJne = !actual.t4PresenciaJne" class="btn" :class="[actual.t4PresenciaJne ? 'btn-primary' : 'btn-outline-secondary']">JNE</button>
                      <button type="button" @click.prevent="actual.t4PresenciaFfaapnp = !actual.t4PresenciaFfaapnp" class="btn" :class="[actual.t4PresenciaFfaapnp ? 'btn-primary' : 'btn-outline-secondary']">FFAA / PNP</button>
                      <button type="button" @click.prevent="actual.t4PresenciaMinisterioPublico = !actual.t4PresenciaMinisterioPublico" class="btn" :class="[actual.t4PresenciaMinisterioPublico ? 'btn-primary' : 'btn-outline-secondary']">Ministerio Público</button>
                      <button type="button" @click.prevent="actual.t4PresenciaDefensoriaDelPueblo = !actual.t4PresenciaDefensoriaDelPueblo" class="btn" :class="[actual.t4PresenciaDefensoriaDelPueblo ? 'btn-primary' : 'btn-outline-secondary']">Defensoría del pueblo</button>
                    </div>
                    <div class="text-muted">Otros:</div>
                    <textarea v-model="actual.t4PresenciaOtros" class="form-control mt-2" type="text" placeholder="Indicar el nombre de la entidad..."></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2"></div>

            <div v-if="eventosActualizacion.length > 0" v-html="eventosActualizacion"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table-hover tbody tr:hover {
  background-color: #f9f9f9;
}
</style>

<script>
export default {
  name: "ficha-turno-1",
  components: {},
  props: {
    localVotacionId: {
      type: Number,
    },
    procesoElectoralId: {
      type: Number,
    },
    expandirContenido: {
      type: Boolean,
    },
    intervaloAutoGuardado: {
      type: Number,
    },
  },
  data: () => ({
    finalizado: false,
    auth: {
      autorizadoEdicionTurno: null,
      mensajeNoAutorizadoEdicionTurno: null,
      puedeCambiarEstadoFinalizado: null,
      puedeEditarTurno: null,
      puedeVisualizarTurno: null,
      puedeFinalizarTurno: null,
      puedeReanudarTurno: null,
      fechaInicioTurno: null,
      fechaFinTurno: null,
      fechaInicioEdicionTurno: null,
      fechaFinEdicionTurno: null,
    },
    ui: {
      colLeftClass: "col-sm-12 col-md-5",
      colRightClass: "col-sm-12 col-md-7",
      estaGuardando: false,
      estaCargandoDatos: false,
      tieneCargaInicial: false,
    },
    temporizadorGuardado: null,
    eventosActualizacion: [],
    actual: {
      t1_PorcentajeMesasInstaladas: null /* String */,
      t4PorcentajeMesasInicioEscrutinios: null /* Bool */,
      t4PresenciaOnpe: null /* Bool */,
      t4PresenciaJne: null /* Bool */,
      t4PresenciaFfaapnp: null /* String */,
      t4PresenciaMinisterioPublico: null /* Bool */,
      t4PresenciaDefensoriaDelPueblo: null /* Bool */,
      t4PresenciaOtros: null /* String */,
    },
    guardado: {
      t1_PorcentajeMesasInstaladas: null /* String */,
      t4PorcentajeMesasInicioEscrutinios: null /* Bool */,
      t4PresenciaOnpe: null /* Bool */,
      t4PresenciaJne: null /* Bool */,
      t4PresenciaFfaapnp: null /* String */,
      t4PresenciaMinisterioPublico: null /* Bool */,
      t4PresenciaDefensoriaDelPueblo: null /* Bool */,
      t4PresenciaOtros: null /* String */,
    },
  }),
  computed: {
    isDirty: function() {
      return JSON.stringify(this.actual) != JSON.stringify(this.guardado);
    },
    mostrarDetalleFicha: function() {
      if (!this.ui.tieneCargaInicial) return false;
      if (!this.auth.autorizadoEdicionTurno) return false;

      return true;
    },
  },
  methods: {
    inicializarGuardado: function() {
      // var vm = this;
      // clearTimeout(this.temporizadorGuardado);
      // this.temporizadorGuardado = setTimeout(function() {
      //   if (vm.isDirty) {
      //     vm.doGuardado();
      //   }
      // }, this.intervaloAutoGuardado);
    },
    doCargarFicha: async function() {
      this.ui.estaCargandoDatos = true;
      var model = {
        localVotacionId: this.localVotacionId,
        procesoElectoralId: this.procesoElectoralId,
      };

      await this.$http.post(this.apiRoute("Sheet", "GetFichaTurnoUno"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          var ficha = response.body.data.ficha;

          var tempActual = this.cloneObject(this.actual);
          var tempGuardado = this.cloneObject(this.guardado);

          tempActual.t1_PorcentajeMesasInstaladas = this.actualizarBaseDiferente("t1_PorcentajeMesasInstaladas", tempActual, tempGuardado, ficha);
          tempActual.t4PresenciaOnpe = this.actualizarBaseDiferente("t4PresenciaOnpe", tempActual, tempGuardado, ficha);
          tempActual.t4PresenciaJne = this.actualizarBaseDiferente("t4PresenciaJne", tempActual, tempGuardado, ficha);
          tempActual.t4PresenciaFfaapnp = this.actualizarBaseDiferente("t4PresenciaFfaapnp", tempActual, tempGuardado, ficha);
          tempActual.t4PresenciaMinisterioPublico = this.actualizarBaseDiferente("t4PresenciaMinisterioPublico", tempActual, tempGuardado, ficha);
          tempActual.t4PresenciaDefensoriaDelPueblo = this.actualizarBaseDiferente("t4PresenciaDefensoriaDelPueblo", tempActual, tempGuardado, ficha);
          tempActual.t4PresenciaOtros = this.actualizarBaseDiferente("t4PresenciaOtros", tempActual, tempGuardado, ficha);
          
          this.actual = this.cloneObject(tempActual);
          this.guardado = this.cloneObject(ficha);
          this.auth = response.body.data.auth;
          //this.inicializarGuardado();
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.ui.tieneCargaInicial = true;
      this.ui.estaCargandoDatos = false;
    },
    actualizarBaseDiferente: function(propiedad, fichaActual, fichaGuardado, fichaActualizada) {
      var valorActual = JSON.stringify(fichaActual[propiedad]);
      var valorGuardado = JSON.stringify(fichaGuardado[propiedad]);
      var valorActualizado = JSON.stringify(fichaActualizada[propiedad]);

      if (valorActual == valorGuardado && valorGuardado != valorActualizado) {
        //this.eventosActualizacion.push("<br>" + propiedad + " se actualiza: " + valorActual + " -> " + valorActualizado);
        return fichaActualizada[propiedad];
      } else {
        //this.eventosActualizacion.push("<br>" + propiedad + " se mantiene: " + valorActual);
        return fichaActual[propiedad];
      }
    },
    doGuardado: async function(omitirNotificacion) {
      if (this.ui.estaGuardando) return;

      if (!this.isFormValid("frm-turno-1")) {
        if (omitirNotificacion != true) {
          //return this.sweetAlert("Hay campos con valores incorrectos. Revise los datos ingresados.");
          this.$bvToast.toast("Hay campos con valores incorrectos. Revise los datos ingresados.", {
            title: "Turno 1",
            autoHideDelay: 5000,
            variant: "warning",
            appendToast: false,
            solid: true,
          });
          return false;
        }
      }

      var actualClone = this.cloneObject(this.actual);

      var model = this.cloneObject(this.actual);
      model.localVotacionId = this.localVotacionId;
      model.procesoElectoralId = this.procesoElectoralId;

      var guardadoExistoso = false;
      this.ui.estaGuardando = true;
      await this.$http.post(this.apiRoute("Sheet", "EditFichaTurnoUno"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          this.guardado = actualClone;

          //this.sweetAlert(response.body.message, "success");

          if (omitirNotificacion != true) {
            this.$bvToast.toast("Los cambios se han guardado correctamente.", {
              title: "Turno 1",
              autoHideDelay: 5000,
              variant: "success",
              appendToast: false,
              solid: true,
            });
          }
          guardadoExistoso = true;

          //Se envía al hub:
          this.$notifyHub.notificarCambioFicha(this.procesoElectoralId, this.localVotacionId, 1);
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.ui.estaGuardando = false;
      return guardadoExistoso;
    },
    doToggleFinalizado: async function() {
      if (this.finalizado) {
        if (!(await this.sweetConfirm("¿Desea desbloquear esta sección?"))) {
          return;
        }
        this.finalizado = !this.finalizado;
      } else {
        if (!(await this.sweetConfirm("¿Desea finalizar esta sección?"))) {
          return;
        }
        this.finalizado = !this.finalizado;
      }
    },
  },
  watch: {
    isDirty: function(newValue, oldValue) {
      // if (newValue && this.intervaloAutoGuardado) {
      //   this.inicializarGuardado();
      // } else {
      //   clearTimeout(this.temporizadorGuardado);
      // }
    },
    actual: {
      deep: true,
      handler() {
        // if (this.intervaloAutoGuardado && !this.ui.estaCargandoDatos) {
        //   this.inicializarGuardado();
        //   //this.eventosActualizacion.push("<br>WATCH ACTUAL");
        // }
      },
    },
  },
  mounted: function() {
    this.initParsley();
    this.doCargarFicha();
  },
  destoyed: function() {
    //clearTimeout(this.temporizadorGuardado);
  },
};
</script>
